<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A solution is prepared by dissolving
        <number-value :value="salt" unit="\text{g}" />
        of
        <chemical-latex content="NaCl" />
        in
        <number-value :value="mass" unit="\text{g}" />
        of water. Assuming the dissolution of the salt does not affect the volume of the solution,
        answer each of the questions below.
      </p>

      <div v-for="question in questions" :key="question.value">
        <p class="mb-2">
          <stemble-latex :content="question.text" />
        </p>

        <calculation-input
          v-model="inputs[question.value]"
          class="mb-5"
          :prepend-text="question.prependText"
          :append-text="question.appendText"
          :disabled="!allowEditing"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220_Task84',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: null,
        inputE: null,
        inputF: null,
        inputG: null,
      },
      questions: [
        {
          text: 'a) What is the molality of the salt solution? ',
          prependText: '$\\text{Molality:}$',
          appendText: '$\\text{m}$',
          value: 'inputA',
        },
        {
          text: 'b) What is the mole fraction of water in the solution?',
          prependText: '$\\text{X}_\\ce{H2O}:$',
          value: 'inputB',
        },
        {
          text: 'c) What is the molarity of the salt solution at $23.6\\,^\\circ\\text{C}$ where the density of water is $0.9989\\,\\text{g/mL}$?',
          prependText: '$\\ce{[NaCl]:}$',
          appendText: '$\\text{M}$',
          value: 'inputC',
        },
        {
          text: 'd) The vapor pressure of pure water at $23.6\\,^\\circ\\text{C}$ is $21.8\\,\\text{mm Hg}$. What is the vapor pressure of the salt solution at this temperature?',
          prependText: '$\\text{vp:}$',
          appendText: '$\\text{mm Hg}$',
          value: 'inputD',
        },
        {
          text: 'e) The boiling point constant of water is $0.512\\,^\\circ\\text{C}/\\text{m}$. At what temperature does the salt solution boil?',
          prependText: '$\\text{T:}$',
          appendText: '$^\\circ\\text{C}$',
          value: 'inputE',
        },
        {
          text: 'f) The freezing point of water is $1.86\\,^\\circ\\text{C}/\\text{m}$. At what temperature does the salt solution freeze?',
          prependText: '$\\text{T:}$',
          appendText: '$^\\circ\\text{C}$',
          value: 'inputF',
        },
        {
          text: 'g) What is the osmotic pressure of the salt solution at at $25.0\\,^\\circ\\text{C}$?',
          prependText: '$\\Pi:$',
          appendText: '$\\text{atm}$',
          value: 'inputG',
        },
      ],
    };
  },
  computed: {
    salt() {
      return this.taskState.getValueBySymbol('salt').content;
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
